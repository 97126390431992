import React, { useState, useMemo, useEffect } from "react";
import countryList from "react-select-country-list";
import DateOfBirthComponent from "./component/dateOfBirthComponent";
import swimImg from "../images/image.png";
import { useNavigate } from "react-router-dom";
import getEventById from "../api/events/getEventById";
import "react-multi-carousel/lib/styles.css";
import "./eventMainPage.scss";
import FileUploadButton from "../components/FileUploadButton";
import HorizontalLine from "./component/horizontalLine";
import Checkout from "./component/checkout";
import addIcon from "../images/add.svg";
import initializePayment from "../api/events/initializePayment";
import { Convert } from "easy-currencies";
import checkout from "../api/events/checkout";
import smallBuoy from "../images/registration/Small Orange Buoy.png";
import bigBuoy from "../images/registration/Dry Bag Orange.png";
import cap from "../images/registration/Cap.png";
import hoodie from "../images/registration/Hoodie.png";
import tshirt from "../images/registration/T-shirt.png";
import tshirtorange from "../images/registration/T-shirt 2.png";
import { ThreeDots } from "react-loader-spinner";
import { getAnalytics, logEvent } from "firebase/analytics";


function CourseRegistration() {
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const [eventData, setEventData] = useState();
  const [formCompleted, setFormCompleted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [eventCartId, setEventCartId] = useState();
  const [checkoutData, setCheckoutData] = useState({});
  const [ageEligibility, setAgeEligibility] = useState("8-80")
  const [loading, setLoading] = useState(false)
  const ageGroups = {
    Aquaman: [
      { "14-19": "Junior" },
      { "20-24": "Adult 20-24" },
      { "25-29": "Adult 25-29" },
      { "30-34": "Adult 30-34" },
      { "35-39": "Adult 35-39" },
      { "40-44": "Adult 40-44" },
      { "45-49": "Adult 45-49" },
      { "50-54": "Adult 50-54" },
      { "55-59": "Adult 55-59" },
      { "60-64": "Adult 60-64" },
      { "65-69": "Adult 65-69" },
      { 70: "Master 70+" },
    ],
    Aquafins: [
      { "10-19": "Junior" },
      { "20-29": "Adult 20-29" },
      { "30-39": "Adult 30-39" },
      { "40-49": "Adult 40-49" },
      { "50-59": "Adult 50-59" },
      { "60-69": "Adult 60-69" },
      { 70: "Master 70+" },
    ],
    Aquakid: [{ "7-9": "U10" }, { "9-11": "U12" }, { "12-14": "U14" }],
  };

  const [error, setError] = useState(false);
  const [fetch, refetch] = useState(false);
  const [formData, setFormData] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      awardCategory: "",
      phoneNumber: "",
      country: "",
      address: "",
      zipCode: "",
      city: "",
      courseId: "",
      gender: "",
      teamName: "",
      dateOfBirth: new Date(),
      emergencyName: "",
      emergencyPhoneNumber: "",
      medicalCondition: "",
      isCondition: false,
      isClub: false,
      isPlatform: false,
      isEvent: false,
      clubName: "",
      platform: "",
      previousEvent: "",
      shirtSize: "",
      supportShirtSize: "",
      hoodieSize: "",
      capSize: "",
      documentType: "",
      documents: "",
      buoySize: "",
      belongToClub: false,
      freeTshirtSize: ""
    },
  ]);

  const [validation, setValidation] = useState(
    formData.map(() => ({
      firstName: true,
      lastName: true,
      email: true,
      phoneNumber: true,
      gender: true,
      country: true,
      city: true,
      address: true,
      zipCode: true,
      emergencyName: true,
      emergencyPhoneNumber: true,
      dateOfBirth: true,
      documentType: true,
      documents: true,
      freeTshirtSize: true,
      courseId: true,
      awardCategory: true,
    }))
  );

  const [iframe, setIframe] = useState();
  const [iframeValu, setIframValu] = useState()

  useEffect(() => {}, [fetch]);

  function calculateAge(dob) {
    const birthday = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();

    // Adjust the age if the current month hasn't reached the birth month yet
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  function findCategory(age, course) {
    if (course != "Aquateam") {
      const categoryArray = ageGroups[course];
      for (let category of categoryArray) {
        const [range, name] = Object.entries(category)[0];
        const [lower, upper] = range.split("-").map(Number);
        if (upper ? age >= lower && age <= upper : age >= lower) {
          return name;
        }
      }
    } else {
      let gender = formData[0].gender;
      let flag = false;
      for (let course of formData) {
        if (gender != course.gender) {
          flag = true;
        }
      }
      if (flag == false) {
        return gender;
      } else {
        return "Mixed";
      }
    }
    return "Unknown Category"; // If no category is found
  }

  const parts = window.location.href.split("/");
  const id = parts[parts.length - 1];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getEventById(id).then((event) => {
      setEventData(event);
      let formDataCp = [...formData];
      formDataCp.map((element) => {
        element.courseId = event.course[0]._id;
        return element;
      });
      const analytics = getAnalytics();
      logEvent(analytics, `Registration_Screen_${event.name}`)
      setFormData(formDataCp);
    });
  }, [formCompleted]);

  function removeEmptyFields(array) {
    for (var obj of array) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
    }
    return array;
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let newFormData = [...formData];
    newFormData[index][name] = value;
    setFormData(newFormData);
    
    let newValidation = [...validation];
    newValidation[index][name] = true;
    setValidation(newValidation);  
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    setError(false); // Reset error state before validation

    const newValidation = formData.map((item) => {
      const itemValidation = {
        firstName: !!item.firstName,
        lastName: !!item.lastName,
        email: !!item.email,
        phoneNumber: !!item.phoneNumber,
        gender: !!item.gender,
        country: !!item.country,
        city: !!item.city,
        address: !!item.address,
        zipCode: !!item.zipCode,
        emergencyName: !!item.emergencyName,
        emergencyPhoneNumber: !!item.emergencyPhoneNumber,
        dateOfBirth: !!item.dateOfBirth,
        documentType: !!item.documentType,
        freeTshirtSize: !!item.freeTshirtSize,
        courseId: !!item.courseId,
        awardCategory: !!item.awardCategory,

        documents: item.documentType === "Minor" || !!item.documents,
      };

      return itemValidation;
    });

    setValidation(newValidation);

    const isValid = newValidation.every((item) =>
      Object.values(item).every(Boolean)
    );

    if (!isValid) {
      setError(true);
      setLoading(false)
      // alert("Please fill all required fields");
      return;
    }

    var reqBody = {
      type: "Event",
      eventId: id,
      eventCart: removeEmptyFields(formData),
    };

    // Proceed with form submission
    initializePayment(reqBody)
        .then((res) => {
         
          setEventCartId(res.eventCartId)
          const eventId = { id: res.eventCartId };
          checkout(eventId)
            .then((response) => {
              setFormCompleted(true);

              for (let i = 0; i < response.content.length; i++) {
                let category = eventData.course.find(
                  (event) => event._id == response.content[i].courseId
                ).courseType;
                if (category == "Aquaman 5.0" || category == "Aquaman 2.0") {
                  category = "Aquaman";
                }
                let age = calculateAge(response.content[i].dateOfBirth);
                response.content[i]["ageCategory"] = findCategory(
                  age,
                  category
                );
                setIframe(res.iFrame);
                setIframValu(res.iFrameValu)
                setCheckoutData(response.content);
                setTotalPrice(response.totalPrice);

                if (response.content[i].buoyPrice) {
                  let category = eventData.course.find(
                    (event) => event._id == response.content[i].courseId
                  ).courseType;
                  if (category == "Aquaman 5.0" || category == "Aquaman 2.0") {
                    category = "Aquaman";
                  }
                  let age = calculateAge(response.content[i].dateOfBirth);
                  response.content[i]["ageCategory"] = findCategory(
                    age,
                    category
                  );
                  setIframe(res.iFrame);
                  setCheckoutData(response.content);
                  setTotalPrice(response.totalPrice);
                }
                if (response.content[i].shirtPrice) {
                  let category = eventData.course.find(
                    (event) => event._id == response.content[i].courseId
                  ).courseType;
                  if (category == "Aquaman 5.0" || category == "Aquaman 2.0") {
                    category = "Aquaman";
                  }
                  let age = calculateAge(response.content[i].dateOfBirth);
                  response.content[i]["ageCategory"] = findCategory(
                    age,
                    category
                  );
                  setIframe(res.iFrame);
                  setCheckoutData(response.content);
                  setTotalPrice(response.totalPrice);
                }
                if (response.content[i].hoodiePrice) {
                  let category = eventData.course.find(
                    (event) => event._id == response.content[i].courseId
                  ).courseType;
                  if (category == "Aquaman 5.0" || category == "Aquaman 2.0") {
                    category = "Aquaman";
                  }
                  let age = calculateAge(response.content[i].dateOfBirth);
                  response.content[i]["ageCategory"] = findCategory(
                    age,
                    category
                  );
                  setIframe(res.iFrame);
                  setCheckoutData(response.content);
                  setTotalPrice(response.totalPrice);
                }
                if (response.content[i].capPrice) {
                  let category = eventData.course.find(
                    (event) => event._id == response.content[i].courseId
                  ).courseType;
                  if (category == "Aquaman 5.0" || category == "Aquaman 2.0") {
                    category = "Aquaman";
                  }
                  let age = calculateAge(response.content[i].dateOfBirth);
                  response.content[i]["ageCategory"] = findCategory(
                    age,
                    category
                  );
                  setIframe(res.iFrame);
                  setCheckoutData(response.content);
                  setTotalPrice(response.totalPrice);
                  setLoading(false)
                }
              }
            })
          .catch((error) => {
            setLoading(false)

            alert(error.response.data.message)
          });
      })
      .catch((error) => {
        setLoading(false)

        alert(error.response.data.message)
      });
  };

  const handleChangeCheckbox = (e, index) => {
    const { name, checked } = e.target;
    let newFormData = [...formData];
    newFormData[index][name] = checked;
    setFormData(newFormData);
  };

  const setBirthDay = (date, index) => {
    let newFormData = [...formData];
    newFormData[index]["dateOfBirth"] = date;
    setFormData(newFormData);
  };

  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const years = Array.from(
    { length: 100 },
    (_, index) => new Date().getFullYear() - index
  );

  const handleDayChange = (e, index) => {
    const day = parseInt(e.target.value);
    setBirthDay(
      new Date(
        formData[index].dateOfBirth?.getFullYear(),
        formData[index].dateOfBirth?.getMonth(),
        day
      ),
      index
    );
  };

  const handleMonthChange = (e, index) => {
    const month = parseInt(e.target.value) - 1; // Month is zero-based in JavaScript Date object
    setBirthDay(
      new Date(
        formData[index].dateOfBirth?.getFullYear(),
        month,
        formData[index].dateOfBirth?.getDate()
      ),
      index
    );
  };

  const handleYearChange = (e, index) => {
    const year = parseInt(e.target.value);
    setBirthDay(
      new Date(
        year,
        formData[index].dateOfBirth?.getMonth(),
        formData[index].dateOfBirth?.getDate()
      ),
      index
    );
  };

  return (
    <>
      <div
        id="menu-wrap"
        className={`menu-back cbp-af-header ${"blue-background"}`}
      >
        <div className="container-appbar">
          <div className="twelve columns">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="appbar-left">
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="logo"
                >
                  {" "}
                  <a href="/"></a>
                </div>
                <ul className="slimmenu">
                  <li>
                    <a href="/">Races</a>
                  </li>
                  <li>
                    <a href="/">Why Aquaman</a>
                  </li>
                  <li>
                    <a href="/">Programs</a>
                  </li>
                  <li>
                    <a href="/">Shop</a>
                  </li>
                  <li>
                    <a href="/">Connect</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {eventData && (
        <div className="registration-Main">
          <div className="carousel-container">
            <img
              key=""
              src={eventData?.coverUrls[0]}
              style={
                window.innerWidth > 700
                  ? {
                      display: "block",
                      height: "100%",
                      margin: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }
                  : {
                      display: "block",
                      height: "100%",
                      margin: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }
              }
            />

            <div className="carousel-text">
              <h3>{eventData.name}</h3>
              <p>{eventData.location}</p>
            </div>
            <div className="carousel-date">{eventData.dateString}</div>
          </div>
          {!formCompleted && (
            <div>
              <h3
                style={{
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  margin: "32px 0 0 0",
                }}
              >
                {" "}
                Registration Form
              </h3>

              <p style={{fontFamily: "Poppins", marginTop: "5px", marginBottom: "24px"}}>The payment will be processed in EGP</p>
              {formData.map((data, index) => {
                return (
                  <>
                    {index > 0 && (
                      <HorizontalLine
                        color={"gray"}
                        thickness={"1px"}
                      ></HorizontalLine>
                    )}
                    {formData.length > 1 && (
                      <p
                        style={{
                          color: "#D69B29",
                          fontSize: "20px",
                          marginTop: "1.5em",
                          marginBottom: "1em",
                          fontWeight: "bold",
                        }}
                      >
                        Person {index + 1}
                      </p>
                    )}
                    <div className="mainRegistrationContainer">
                      <div className="registrationSubContainer">
                        <input
                          className={`formInput ${!validation[index].firstName ? "invalid" : ""}`}
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name*"
                          value={data.firstName}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <input
                          className={`formInput ${!validation[index].lastName ? "invalid" : ""}`}
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name*"
                          value={data.lastName}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <input
                          className={`formInput ${!validation[index].email ? "invalid" : ""}`}
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          value={data.email}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <input
                          className={`formInput ${!validation[index].phoneNumber ? "invalid" : ""}`}
                          type="number"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number*"
                          value={data.phoneNumber}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <select
                          className={`formInput select ${!validation[index].gender ? "invalid" : ""}`}
                          id="gender"
                          name="gender"
                          placeholder="Gender*"
                          value={data.gender}
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="">Select Gender*</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>

                        <label
                          htmlFor="dateOfBirth"
                          style={{ color: "#112348" }}
                        >
                          Date Of Birth* :
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "inherit",
                            width: "-webkit-fill-available",
                          }}
                        >
                          {/* Day Dropdown */}
                          <select
                            style={{ width: "9em", height: "2.5em" }}
                            className={`formInput select ${!validation[index].dateOfBirth ? "invalid" : ""}`}
                            value={data.dateOfBirth?.getDate()}
                            onChange={(e) => handleDayChange(e, index)}
                          >
                            <option value="">Day</option>
                            {days.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>

                          {/* Month Dropdown */}
                          <select
                            style={{ width: "9em", height: "2.5em" }}
                            className={`formInput select ${!validation[index].dateOfBirth ? "invalid" : ""}`}
                            value={data.dateOfBirth?.getMonth() + 1}
                            onChange={(e) => handleMonthChange(e, index)}
                          >
                            <option value="">Month</option>
                            {months.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>

                          {/* Year Dropdown */}
                          <select
                            style={{ width: "9em", height: "2.5em" }}
                            className={`formInput select ${!validation[index].dateOfBirth ? "invalid" : ""}`}
                            value={data.dateOfBirth?.getFullYear()}
                            onChange={(e) => handleYearChange(e, index)}
                          >
                            <option value="">Year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "inherit",
                            width: "-webkit-fill-available",
                          }}
                        >
                          <select
                            className={`formInput select ${!validation[index].documentType ? "invalid" : ""}`}
                            id="documentType"
                            name="documentType"
                            value={data.documentType}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Document*</option>
                            <option value="nationalId">National ID</option>
                            <option value="passportId">Passport ID</option>
                            <option value="minor">Minor</option>
                          </select>
                          <input
                            className={`formInput ${!validation[index].documents ? "invalid" : ""}`}
                            style={{ marginLeft: "1em" }}
                            type="text"
                            id="documents"
                            name="documents"
                            placeholder="Id*"
                            value={data.documents}
                            onChange={(e) => handleChange(e, index)}
                            required
                          />
                        </div>

                        <label htmlFor="gender" style={{ color: "#112348"}}>Residence* :</label>
                        <select
                          className={`formInput select ${!validation[index].country ? "invalid" : ""}`}
                          id="country"
                          name="country"
                          value={data.country}
                          onChange={(e) => handleChange(e, index)}
                        >
                          {options.map((country) => {
                            return (
                              <option value={country.label}>
                                {country.label}
                              </option>
                            );
                          })}
                        </select>

                        <input
                          className={`formInput ${!validation[index].city ? "invalid" : ""}`}
                          type="text"
                          id="city"
                          name="city"
                          placeholder="City*"
                          value={data.city}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <input
                          className={`formInput ${!validation[index].address ? "invalid" : ""}`}
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Address*"
                          value={data.address}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <input
                          className={`formInput ${!validation[index].zipCode ? "invalid" : ""}`}
                          type="text"
                          id="zipCode"
                          name="zipCode"
                          placeholder="Zip Code*"
                          value={data.zipCode}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <label style={{ marginTop: "3em", color: "#112348" }}>
                          Emergency Contact Name
                        </label>
                        <input
                          className={`formInput ${!validation[index].emergencyName ? "invalid" : ""}`}
                          type="text"
                          id="emergencyName"
                          name="emergencyName"
                          placeholder="name*"
                          value={data.emergencyName}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        <label style={{ marginTop: "16px", color: "#112348" }}>
                          Emergency Contact Number
                        </label>
                        <input
                          className={`formInput ${!validation[index].emergencyPhoneNumber ? "invalid" : ""}`}
                          type="number"
                          id="emergencyPhoneNumber"
                          name="emergencyPhoneNumber"
                          placeholder="Phone Number*"
                          value={data.emergencyPhoneNumber}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />

                        {eventData.course.find(
                          (item) => item._id == formData[0].courseId
                        ).courseType != "Aquateam" &&  (
                          <>
                            <label
                              htmlFor="course"
                              style={{ marginTop: "1em",  color: "#112348" }}
                            >
                              Race Category* :
                            </label>
                            <select
                              className={`formInput select ${!validation[index].courseId ? "invalid" : ""}`}
                              style={{marginBottom:"0.5em"}}
                              id="courseId"
                              name="courseId"
                              value={data.courseId}
                              onChange={(e) => {
                                handleChange(e, index);
                                setAgeEligibility( eventData.course.find(
                                  (item) => item._id == e.target.value
                                ).ageEligibility)
                                if (
                                  eventData.course.find(
                                    (item) => item._id == e.target.value
                                  ).courseType == "Aquateam"
                                ) {
                                  let newFormData1 = {
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    phoneNumber: "",
                                    nationality: "",
                                    awardCategory: "",
                                    country: "",
                                    address: "",
                                    zipCode: "",
                                    city: "",
                                    courseId: formData[0].courseId,
                                    gender: "",
                                    dateOfBirth: new Date(),
                                    emergencyName: "",
                                    emergencyPhoneNumber: "",
                                    shirtSize: "",
                                    supportShirtSize: "",
                                    hoodieSize: "",
                                    documents: [],
                                    belongToClub: false,
                                  };

                                  let newFormData2 = {
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    phoneNumber: "",
                                    nationality: "",
                                    country: "",
                                    awardCategory: "",
                                    address: "",
                                    zipCode: "",
                                    city: "",
                                    courseId: formData[0].courseId,
                                    gender: "",
                                    dateOfBirth: new Date(),
                                    emergencyName: "",
                                    emergencyPhoneNumber: "",
                                    shirtSize: "",
                                    supportShirtSize: "",
                                    hoodieSize: "",
                                    documents: [],
                                    belongToClub: false,
                                  };

                                  let newValidation1= {
                                    firstName: true,
                                    lastName: true,
                                    email: true,
                                    phoneNumber: true,
                                    gender: true,
                                    country: true,
                                    city: true,
                                    address: true,
                                    zipCode: true,
                                    emergencyName: true,
                                    emergencyPhoneNumber: true,
                                    dateOfBirth: true,
                                    documentType: true,
                                    documents: true,
                                    freeTshirtSize: true,
                                    courseId: true,
                                    awardCategory: true,
                                  }

                                  let newValidation2 = {
                                    firstName: true,
                                    lastName: true,
                                    email: true,
                                    phoneNumber: true,
                                    gender: true,
                                    country: true,
                                    city: true,
                                    address: true,
                                    zipCode: true,
                                    emergencyName: true,
                                    emergencyPhoneNumber: true,
                                    dateOfBirth: true,
                                    documentType: true,
                                    documents: true,
                                    freeTshirtSize: true,
                                    courseId: true,
                                    awardCategory: true,
                                  }

                                  setValidation([...validation, newValidation1, newValidation2])

                                  setFormData([
                                    ...formData,
                                    newFormData1,
                                    newFormData2,
                                  ]);
                                  refetch(true);
                                }
                              }}
                            >
                             {eventData.course
    .filter((course) => course._id !== '6630f7e9a6c8416018edb600') // Exclude the specific course ID
    .map((course) => (
      <option key={course._id} value={course._id}>
        {course.courseType}
      </option>
    ))}
                            </select>
                          </>
                        )}

                        {  eventData.course.find(
                                    (item) => item._id == formData[0].courseId
                                  ).courseType == "Aquateam" &&  index == 0 && (
                          <>
                           <input
                          className="formInput"
                          type="text"
                          id="teamName"
                          name="teamName"
                          style={{borderColor: "#D69B29", margin: "1em 0"}}
                          placeholder="Team Name*"
                          value={data.teamName}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                          </>
                        )}
{ageEligibility && <p style={{marginBottom: "1.5em"}}>allowed age range for this race is {ageEligibility}</p>}
                        <select
                          className={`formInput select ${!validation[index].awardCategory ? "invalid" : ""}`}
                          id="awardCategory"
                          name="awardCategory"
                          placeholder="Gender*"
                          value={data.awardCategory}
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="">Select Award Category*</option>
                          <option value="age">Age Group Awards</option>
                          <option value="inspiration">Inspiration Awards</option>
                        </select>

                        <label style={{ width: "-webkit-fill-available",  color: "#112348",marginTop: "1em"}}>
                          Free T-shirt eligible! What T-shirt size are you?*
                          <select
                            className={`formInput select ${!validation[index].freeTshirtSize ? "invalid" : ""}`}
                            id="freeTshirtSize"
                            name="freeTshirtSize"
                            value={data.freeTshirtSize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="XXS">XXS</option>
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">
                              M
                            </option>
                            <option value="L">L</option>
                            <option value="XL">
                              XL
                            </option>
                            <option value="XXL">XXL</option>
                          </select>
                        </label>
                      </div>
                      <div className="registrationSubContainer">

                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            color: "#112348",
                            marginTop: "0.5em"
                          }}
                        >
                          Have you participated in open water swimming events
                          before?
                          <input
                            style={{ width: "20px", margin: "0px" }}
                            type="checkbox"
                            name="isEvent"
                            checked={data.isEvent}
                            onChange={(e) => handleChangeCheckbox(e, index)}
                          />
                        </label>

                        {data.isEvent && (
                          <input
                            className={`formInput`}
                            type="text"
                            id="previousEvent"
                            name="previousEvent"
                            placeholder="Name of the Event*"
                            value={data.previousEvent}
                            onChange={(e) => handleChange(e, index)}
                            required
                          />
                        )}

                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            color: "#112348",
                            marginTop: "0.5em"
                          }}
                        >
                          Do you belong to any sports club?*
                          <input
                            style={{ width: "20px", margin: "0px" }}
                            type="checkbox"
                            name="isClub"
                            checked={data.isClub}
                            onChange={(e) => handleChangeCheckbox(e, index)}
                          />
                        </label>
                        {data.isClub && (
                          <input
                            className={`formInput`}
                            type="text"
                            id="clubName"
                            name="clubName"
                            placeholder="Name of the club*"
                            value={data.clubName}
                            onChange={(e) => handleChange(e, index)}
                            required
                          />
                        )}

                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            color: "#112348",
                           marginTop: "0.5em"
                          }}
                        >
                          Do you have any medical conditions?*
                          <input
                            style={{ width: "20px", margin: "0px" }}
                            type="checkbox"
                            name="isCondition"
                            checked={data.isCondition}
                            onChange={(e) => handleChangeCheckbox(e, index)}
                          />
                        </label>

                        {data.isCondition && (
                          <input
                            className={`formInput`}
                            type="text"
                            id="medicalCondition"
                            name="medicalCondition"
                            placeholder="conditions*"
                            value={data.medicalCondition}
                            onChange={(e) => handleChange(e, index)}
                            required
                          />
                        )}

                        <label style={{ color: "#112348", marginTop: "0.8em" }}>
                          How did you hear about this event?*
                        </label>
                        <input
                          className={`formInput `}
                          type="text"
                          id="platform"
                          name="platform"
                          placeholder="Name of the Platform*"
                          value={data.platform}
                          onChange={(e) => handleChange(e, index)}
                          required
                        />
                        <label
                          style={{
                            width: "-webkit-fill-available",
                            color: "#112348",
                            marginTop: "2em",
                          }}
                        >
                          Do you want to purchase an Aquaman safety buoy?
                          <span
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              height: "120px",
                              marginTop: "1em",
                              marginBottom: "1em",
                            }}
                          >
                            <img src={smallBuoy} />
                            <img src={bigBuoy} />
                          </span>
                          <select
                            className={`formInput select`}
                            id="buoySize"
                            name="buoySize"
                            value={data.buoySize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="smallO">
                              Small Buoy Orange $ 30
                            </option>
                            <option value="smallP">Small Buoy Pink $ 30</option>
                            <option value="bigO">Dry Bag Orange $ 40</option>
                            <option value="bigP">Dry Bag Pink $ 40</option>
                          </select>
                        </label>

                        <label
                          style={{
                            width: "-webkit-fill-available",
                            color: "#112348",
                          }}
                        >
                          Do you want to purchase an Aquaman cap?
                          <span
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              height: "120px",
                              marginTop: "1em",
                              marginBottom: "1em",
                            }}
                          >
                            <img src={cap} />
                          </span>
                          <select
                            className={`formInput select`}
                            id="capSize"
                            name="capSize"
                            value={data.capSize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="darkBlue">
                              Dark Blue ONE SIZE $ 10
                            </option>
                            <option value="darkBlue">Blue ONE SIZE $ 10</option>
                            <option value="Green">Green ONE SIZE $ 10</option>
                            <option value="orange">
                              Neon Orange ONE SIZE $ 10
                            </option>
                            <option value="yellow">
                              Neon Yellow ONE SIZE $ 10
                            </option>
                            <option value="pink">Pink ONE SIZE $ 10</option>
                            <option value="black">Black ONE SIZE $ 10</option>
                          </select>
                        </label>

                        <label
                          style={{
                            width: "-webkit-fill-available",
                            color: "#112348",
                          }}
                        >
                          Do you want to purchase an Aquaman hoodie?
                          <span
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              height: "120px",
                              marginTop: "1em",
                              marginBottom: "1em",
                            }}
                          >
                            <img src={hoodie} />
                          </span>
                          <select
                            className={`formInput select`}
                            id="hoodieSize"
                            name="hoodieSize"
                            value={data.hoodieSize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="small">Small $ 20</option>
                            <option value="medium">medium $ 20</option>
                            <option value="large">Large $ 20</option>
                            <option value="xlarge">x-Large $ 20</option>
                            <option value="xxlarge">xx-Large $ 20</option>
                          </select>
                        </label>
                        <label
                          style={{
                            width: "-webkit-fill-available",
                            color: "#112348",
                          }}
                        >
                          Do you want to purchase an Aquaman T-shirt?
                          <span
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              height: "110px",
                              marginTop: "1em",
                              marginBottom: "1em",
                            }}
                          >
                            <img src={tshirt} />
                          </span>
                          <select
                            className={`formInput select`}
                            id="shirtSize"
                            name="shirtSize"
                            value={data.shirtSize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="blueXXS">XXS $ 15</option>
                            <option value="blueXS">XS $ 15</option>
                            <option value="blueS">S $ 15</option>
                            <option value="blueM">M $ 15</option>
                            <option value="blueL"> L $ 15</option> 
                            <option value="blueXL">XL $ 15</option>
                            <option value="blueXXL">XXL $ 15</option>
                          </select>
                        </label>

                        <label
                          style={{
                            width: "-webkit-fill-available",
                            color: "#112348",
                          }}
                        >
                          Do you want to purchase an Aquaman support T-shirt?
                          <span
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              height: "110px",
                              marginTop: "1em",
                              marginBottom: "1em",
                            }}
                          >
                            <img src={tshirtorange} />
                          </span>
                          <select
                            className={`formInput select`}
                            id="supportShirtSize"
                            name="supportShirtSize"
                            value={data.supportShirtSize}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <option value="">Select Size</option>
                            <option value="yellowXXS">XXS $ 15</option>
                            <option value="yellowXS">XS $ 15</option>
                            <option value="yellowS">S $ 15</option>
                            <option value="yellowM">M $ 15</option>
                            <option value="yellowL"> L $ 15</option> 
                            <option value="yellowXL">XL $ 15</option>
                            <option value="yellowXXL">XXL $ 15</option>
                          </select>
                        </label>

                    
                      </div>
                    </div>
                  </>
                );
              })}
              <br />
              <HorizontalLine color={"gray"} thickness={"1px"}></HorizontalLine>
              {eventData.course.find((item) => item._id == formData[0].courseId)
                .courseType != "Aquateam" && (
                <span
                  style={{ display: "flex" }}
                  onClick={() => {
                    let newFormData = {
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      nationality: "",
                      country: "",
                      awardCategory: "",
                      address: "",
                      zipCode: "",
                      city: "",
                      courseId: "",
                      gender: "",
                      dateOfBirth: new Date(),
                      emergencyName: "",
                      emergencyPhoneNumber: "",
                      shirtSize: "",
                      supportShirtSize: "",
                      hoodieSize: "",
                      documents: [],
                      belongToClub: false,
                    };

                    let newValidation = {
                      firstName: true,
                      lastName: true,
                      email: true,
                      phoneNumber: true,
                      gender: true,
                      country: true,
                      city: true,
                      address: true,
                      zipCode: true,
                      emergencyName: true,
                      emergencyPhoneNumber: true,
                      dateOfBirth: true,
                      documentType: true,
                      documents: true,
                      freeTshirtSize: true,
                      courseId: true,
                      awardCategory: true,
                    }

                    setValidation([...validation, newValidation])

                    setFormData([...formData, newFormData]);
                  }}
                >
                  <img src={addIcon} />
                  <p style={{ color: "#D69B29", marginLeft: "4px" }}>
                    Add A Swimmer
                  </p>
                </span>
              )}
            <button className="SubmitBtn" disabled={loading} onClick={handleSubmit}>
            {loading ? <ThreeDots
        visible={true}
        width="50"
        height="50"
        color="#fff"
        radius="7"
        ariaLabel="three-dots-loading"
        /> :
      "Proceed to checkout"}
</button>
            </div>
          )}
        </div>
      )}
      {formCompleted && (
        <Checkout
          iframe={iframe}
          iframeValu={iframeValu}
          events={eventData}
          content={checkoutData}
          price={totalPrice}
          eventCartId={eventCartId}
        />
      )}
    </>
  );
}

export default CourseRegistration;
