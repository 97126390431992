import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/layout.css";
import "./css/base.css";
import "./css/skeleton.css";
import Gallery from "./components/Slideshow";
import getAllEvents from "./api/events/getAllEvents";
import Partners from "./components/partners";
import contactUs from "./api/contactus/contactUs";
import { getAnalytics, logEvent } from "firebase/analytics";

function Dashboard() {
  const navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [scrollTrigger, setScrollTrigger] = useState("");
  const [events, setEvents] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    info: ''
  });


  const analytics = getAnalytics();
logEvent(analytics, 'screen_view', {
  firebase_screen: "DASHBOARD", 
})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await contactUs(formData).then((_) => {
        alert("Thank you for contacting us!")
      }); // Call postEventData function with formData
      setFormData({
        name: '',
        email: '',
        info: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again later.');
    }
  };

  useEffect(() => {
    getAllEvents().then((events) => {
      setEvents(events);
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  useEffect(() => {
    const element = document.getElementById(scrollTrigger);
    const appBarHeight = document.getElementById("menu-wrap").offsetHeight;

    if (scrollTrigger == "top") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (element) {
      const offset = element.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
    setScrollTrigger("");
  }, [scrollTrigger]);

  return (
    <div>
      <div className="top-flair">
       <div className="flair-cont">
        <button onClick={() => navigate("event/66088a69f4e7f62a18f1f4ed")} className="flair-btn">Register Now</button>
        <p className="flair-content"><strong style={{letterSpacing: "0.1em"}}>Announcement Aquaman Somabay Tier 2 Registration is Now Open </strong>  </p>
        </div>
      </div>
      <div
        id="menu-wrap"
        className={`menu-back cbp-af-header ${
          scrolling ? "blue-background" : ""
        }`}
      >
       
        <div className="container-appbar">
          <div className="twelve columns" style={{marginTop: "1.5em"}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="appbar-left">
                <div
                  className="logo"
                  onClick={() => {
                    setScrollTrigger("top");
                  }}
                >
                  {" "}
                  <a href="home"></a>
                </div>
                <ul className="slimmenu">
                  <li>
                    <a
                      onClick={() => {
                        setScrollTrigger("races");
                      }}
                    >
                      Races
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setScrollTrigger("why");
                      }}
                    >
                      Why Aquaman
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setScrollTrigger("programs");
                      }}
                    >
                      Programs
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setScrollTrigger("store");
                      }}
                    >
                      Shop
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setScrollTrigger("blog");
                      }}
                    >
                      Connect
                    </a>
                  </li>
                </ul>
              </div>
              <div className="main-button">Become a Partner</div>
            </div>
          </div>
        </div>
      </div>
      <div className="cd-main">
        <div className="section full-height" id="home">
          <div className="hero-top">
            <div className="container-top">
              <div className="eight columns width-inherit">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>
                    <p className="container-top-main">Conquer The Waves:</p>
                    <p className="container-top-main">
                      Aquaman Open Water Swimming
                    </p>
                    <br />
                    <p className="container-top-subtitle">
                      Experience the thrill of open water swimming races with
                      Aquaman. Join us for exhilarating competitions in
                      breathtaking natural settings. Dive into the challenge and
                      register now for your chance to conquer the waves!
                    </p>
                    <br />
                    <span style={{display: "flex", flexWrap: "wrap"}}>
                    <div className="register-button" style={{marginRight: "16px"}} onClick={() => navigate("event/66088a69f4e7f62a18f1f4ed")}>Register Now</div>
                  <div className="register-button mobile">Become a Partner</div>
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-14vh",
                      left: "50%",
                      transform: "translateX(-50%)",
                      margin: "0px",
                    }}
                  >
                    <p
                      className="smaller"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontStyle: "italic",
                        fontSize: "16px",
                        fontWeight: 900,
                      }}
                    >
                      POWERED BY BOOST
                    </p>
                    <p
                      className="smaller"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "16px",
                        marginTop: "4px",
                      }}
                    >
                      join the{" "}
                      <span
                        className="smaller"
                        style={{
                          color: "#D69B29",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "20px",
                          marginTop: "4px",
                        }}
                      >
                        50+
                      </span>{" "}
                      Partners that made Aquaman happen
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="imgj"
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>


          





          <Partners />
        </div>
      </div>

      <div id="races">
        <div className="section" style={{ backgroundColor: "#D69B29" }}>
          <div className="container">
            <div className="twelve columns">
              <div
                className="header-text main-pages"
                style={{ marginTop: "50px" }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                  }}
                >
                  Upcoming Races
                </span>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    fontFamily: "Poppins",
                    color: "white",
                    marginTop: "24px",
                    textAlign: "center",
                    lineHeight: "1.2", // Adjust this value as needed
                  }}
                >
                  Discover Your Dream Swim Race and dive into Aquaman's
                  Selection Below!
                </div>
              </div>
            </div>
          </div>
        </div>

        {window.innerWidth < 1200 ? (
          <div>
            {events.map((event, index) => (
              <img
                key={index}
                src={event.imageUrl}
                alt={`Image ${index + 1}`}
                style={{ width: "100%", cursor: "pointer" }}
                onClick={(_) => {
                  // if(event.isActive){
                    navigate(`/event/${event._id}`);
                  // }
                }}
              />
            ))}
          </div>
        ) : (
          <Gallery
            imageUrls={events.map((event) => event.imageUrl)}
            ids={events.map((event) => event._id)}
            isActives={events.map((event) => true)}
          />
        )}
      </div>
      {/* <div className="slider-container">
        <div className="arrow arrow-left" onClick={() => {}}>&#10094;</div>
        <div className="slider">
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 1" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 2" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
        </div>
        <div className="arrow arrow-right" onClick={() => {}}>&#10095;</div>
      </div> */}

      <div className="clear"></div>
      

      <div id="why">
        <div className="whyaquaman">
          <div className="whyaquaman-left">
            <img
              src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/backgrounds/AM_Homepage_About-05.png"
              class="whyaquaman-left-image"
              alt="Your Image"
            />
          </div>

          <div className="whyaquaman-right">
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#D69B29",
                fontSize: "10px",
              }}
            >
              ABOUT US
            </p>
            <br />
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#112348",
                fontSize: "40px",
              }}
            >
              Why Aquaman
            </p>
            <br />
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#112348",
                fontSize: "16px",
              }}
            >
              At Aquaman, we're your go to for open water swimming competitions.
              Partnered with Boost for top-notch event hosting, we offer six
              races suited for all levels: Aquaman 10km, Aquaman 5.0 5km,
              Aquaman 2.0 2km, Aquakids 500m, Aquateams 3X500m, and Aquafins
              1km. Dive into the excitement, whether you're a seasoned pro or
              just starting out. Start your swimming journey with us today!
            </p>
          </div>
        </div>

        <div
          class="section padding-top-bottom-small"
          style={{ backgroundColor: "#112348" }}
        >
          <div class="container flex-column" style={{ display: "flex" }}>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#D69B29", fontWeight: "bold" }}
                  >
                    60+
                  </em>
                </div>
                <p style={{ fontSize: "12px" }}>
                  Countries participate in our event {window.innerWidth > 780 && <br/>} each year
                </p>
              </div>
            </div>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#D69B29", fontWeight: "bold" }}
                  >
                    25,000+
                  </em>
                </div>
                <p style={{ fontSize: "12px" }}>
                  Swimmers participate in events and our swim in our academies
                </p>
              </div>
            </div>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#D69B29", fontWeight: "bold" }}
                  >
                    25+
                  </em>
                </div>
                <p style={{ fontSize: "12px" }}>
                  partners in success around {window.innerWidth > 780 && <br/>} the world
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
      <div id="programs">
        <div class="section padding-top-bottom grey-background">
          <div class="container">
            <div class="twelve columns">
              <div class="header-text main-pages">
                <span style={{ color: "#112348" }}>Our Programs</span>
                <br />
                <br />
                <h4>
                  Explore our programs: become a Certified Aquaman Coach, train
                  on-
                </h4>
                <h4>ground, or access online race training.</h4>
              </div>
            </div>
            <div
              class="four columns"
              data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
            >
              <div class="team-box">
              <img src={"https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-10.png"}/>
              </div>
            </div>
            <div class="four columns">
              <div class="team-box">
              <img src={"https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-11.png"}/>

              </div>
            </div>
            <div class="four columns">
              <div class="team-box">
                <img src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-12.png" alt="" />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
      <div id="store">
        <div class="section" style={{ backgroundColor: "#D69B29" }}>
          <div class="container">
            <div class="twelve columns">
              <div class="header-text main-pages" style={{ marginTop: "50px" }}>
                <span>Aquaman's Store</span>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    fontFamily: "Poppins",
                    color: "white",
                    marginTop: "32px",
                    textAlign: "center",
                    lineHeight: "1.2", // Adjust this value as needed
                  }}
                >
                  Discover Your Dream Swim Race and dive into Aquaman's
                  Selection Below!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
      <div>
        <div class="section padding-top-bottom">
          <div class="parallax-3"></div>
          <div
            class="container"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0 32px",
              maxWidth: "100%",
              height: "auto",
            }}
          >
            <img
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/store/AM_Homepage_T-shirt-13.png"
            ></img>

            <img
              className="hide-mobile"
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/store/AM_Homepage_Hoodie-14.png"
            ></img>
            <img
              className="hide-mobile-two"
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/store/AM_Homepage_Long%20Sleeve-15.png"
            ></img>
          </div>
          <div>
            <div
              data-lang="en"
              style={{
                backgroundColor: "#D69B29",
                fontFamily: "Poppins",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "4px",
                padding: "12px 16px",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "fit-content",
                margin: "16px auto",
              }}
            >
              Shop Now ..Coming soon
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>

      <div id="blog" class="contactus-container">
        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#112348",
            fontSize: "36px",
          }}
        >
          Get in Touch
        </span>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "#112348",
            fontSize: "20px",
            marginTop: "32px",
            textAlign: "center",
          }}
        >
          We're here to answer any questions you have.
        </div>
      </div>
      <form name="ajax-form" id="ajax-form" onSubmit={handleSubmit} method="post">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s">
            <label for="name">
              <span class="error" id="err-name">
                please enter name
              </span>
            </label>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="Your Name: *"
              onChange={handleChange}
            />
          </div>
          <div data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s">
            <label for="email">
              <span class="error" id="err-email">
                please enter e-mail
              </span>
              <span class="error" id="err-emailvld">
                e-mail is not a valid format
              </span>
            </label>
            <input
              name="email"
              id="email"
              type="text"
              placeholder="E-Mail: *"
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          class="twelve columns"
          data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label for="info"></label>
            <textarea
              name="info"
              id="info"
              placeholder="Tell Us Everything"
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div
          class="twelve columns"
          data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
        >
          <div>
            <button class="contactus-button" data-lang="en">
              Submit
            </button>
          </div>
        </div>
        <div class="clear"></div>
        <div class="error text-align-center" id="err-form">
          There was a problem validating the form please check!
        </div>
        <div class="error text-align-center" id="err-timedout">
          The connection to the server timed out!
        </div>
        <div class="error" id="err-state"></div>
      </form>

      <div className="clear"></div>
    </div>
  );
}

export default Dashboard;
