import axios from 'axios';

const getEventById = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(process.env.REACT_APP_BASE_URL + "/event/" + id) // Append the id to the URL
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export default getEventById;