import React from "react";
function PartnersSection({partners}) {
  return (

    <div className="partners-main">
      <div className="partners-section">
        <h3>{partners[0].name}</h3>
        <span className="partners-span">
        {partners[0].imageUrls.map((url, index) => (
        <img key={index}  style={{margin: "0.5em 1em"}} src={url} alt={`Image ${index}`} />
      ))}
        </span>
      </div>
      <div className="partners-section">
        <h3 style={{marginTop:"1em"}}>{partners[1].name}</h3>
        <span className="partners-span">
        {partners[1].imageUrls.map((url, index) => (
        <img key={index} style={{margin: "0.5em 1em", width: "175px", minWidth:"175px"}} src={url} alt={`Image ${index}`} />
      ))}
        </span>
      </div>
      <div className="partners-section">
        <h3 style={{marginTop:"1em"}}>{partners[2].name}</h3>
        <span className="partners-span">
        {partners[2].imageUrls.length > 0 ? partners[2].imageUrls.map((url, index) => (
        <img key={index} style={{margin: "0.5em 1em"}} src={url} alt={`Image ${index}`} />
      )): "TBD"}
        </span>
      </div>
    </div>
  );
}

export default PartnersSection;
