import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import EventMainPage from "./event/eventMainPage";
import Dashboard from "./dashboard";
import CourseRegistration from "./event/courseRegistration";
import "./firebaseConfig";
import PaymentSuccess from "./event/component/PaymentSuccessPage";
import MakanakForm from "./makanakForm/MakanakForm";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Dashboard />} path="/" exact />
        <Route element={<EventMainPage />} path="/event/:id" exact />
        <Route element={<CourseRegistration />} path="/event/payment/:id" exact />
        <Route element={<PaymentSuccess />} path="/success" exact />
        <Route element={<MakanakForm/>} path="/Makanak" exact />

      </Routes>
    </Router>
  );
}

export default App;
