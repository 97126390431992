import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./css/layout.css";
import "./css/base.css"
import "./css/skeleton.css"
import logo from "./images/logofull.svg"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App  className="App"/>
    <div class="section padding-top-bottom-small footer">
        <div class="parallax-footer"></div>

        <div>
          <div class="footer-flex">
            <div class="left-side">
              <div className="footer-logo-full">
                <div className="logo-footer"></div>
                <br />
                <br />
                <div class="social-footer">
                  <ul class="list-social">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <a className="insta" href="https://www.instagram.com/aquaman.openwater?igsh=MTR4dG5xZDczNDl0eQ%3D%3D&utm_source=qr" target='_blank'  />
                      <a className="facebook" href="https://www.facebook.com/aquaman.openwaterswimming?mibextid=LQQJ4d" target='_blank' />
                      <a className="mail" href="mailto:communication@aquaman.co" />
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div class="footer-column">
              <p class="footer-column-title">RACES</p>
              <p class="footer-column-items">Open Water Swimming</p>
              <p class="footer-column-items">Obstacle Course</p>
              <p class="footer-column-items">Aquathlon</p>
            </div>
            <div class="footer-column">
              <p class="footer-column-title">WHY AQUAMAN</p>
              <p class="footer-column-items">About Us</p>
              <p class="footer-column-items">Careers</p>
              <p class="footer-column-items">Volunteer</p>
            </div>
            <div class="footer-column">
              <p class="footer-column-title">PROGRAMS</p>
              <p class="footer-column-items">Become A Coach</p>
              <p class="footer-column-items">Train On-ground</p>
              <p class="footer-column-items">Online Race Training</p>
            </div>
            <div class="footer-column">
              <p class="footer-column-title">SHOP</p>
              <p class="footer-column-items">Race Gear</p>
              <p class="footer-column-items">Aquaman Merchandise</p>
            </div>
          </div>
          <div class="social-footer-mobiles">
            <ul class="list-social">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "80vw",
                  margin: "24px auto 8px auto",
                }}
              >
                <div className="insta" alt="" />
                <div className="facebook" alt="" />
                <div className="youtube" alt="" />
                <div className="tiktok" alt="" />
                <div className="mail" alt="" />
              </div>
            </ul>
          </div>
          <div class="footer-copy-text">
            <p>
              Terms and Conditions / Website Terms of Use / Privacy Policy /
              Cookie Preferences © 2024 Aquaman.openwater
            </p>
          </div>
        </div>
      </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
