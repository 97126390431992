import React, { useState } from 'react';
import "./component.scss"

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="collapsible-section">
      <button className={isOpen ?"collapsible-title" : "collapsible-title-close" } onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span className={isOpen ? "collapsible-arrow" : "collapsible-arrow-close"}>{isOpen ?  <i class="fa fa-angle-up" style={{fontSize:"1.5em"}}/> : <i class="fa fa-angle-down" style={{fontSize:"1.5em"}}/>
}</span>
      </button>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default CollapsibleSection;
