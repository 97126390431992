import axios from "axios";

const contactUs = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_BASE_URL + "/contact", data)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export default contactUs;