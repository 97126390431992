import React, {useState} from 'react';
import CollapsibleSection from "./collapsibleSection.js"


function CourseComponent({courseInfo, pdf}) {

    const [selectedImage, setSelectedImage] = useState(  'https://www.healthdigest.com/img/gallery/when-you-swim-every-day-this-is-what-happens-to-your-body/l-intro-1617716220.jpg');
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);


    // Function to handle button clicks and change the selected image
    const handleImageSelect = (index) => {
      setSelectedButtonIndex(index);

    };


    const imagesTitle = courseInfo.map(course => course.courseType);
const images = courseInfo.map(course => course.courseMapUrl);
const technicalInfoUrls = courseInfo.map(course => course.technicalInfoUrl);

    


   

    return ( <div style={window.innerWidth > 700 ? {paddingTop: "64px"}: {paddingTop: "16px"}}>
        <div className="registration-firstPart" >
        <h3>Check Out The 2024 Course Preview</h3>
        </div>
<div style={{textAlign: "center"}}>
      {/* Render buttons for each image */}
      {window.innerWidth > 700 ? (<div className='images-btn-Container'>
        {images.map((image, index) => (
            <>
                         {console.log(selectedButtonIndex , index, selectedButtonIndex === index)}

          <button key={index} className={selectedButtonIndex === index ? "image-btn-Clicked": ""} onClick={() => handleImageSelect(index)}>
             {imagesTitle[index]}

          </button>
            </>
        ))}
      </div>) : (<select style={{margin: "20px 0",fontSize:"16px"}} value={selectedButtonIndex} onChange={(event) => {
             handleImageSelect(event.target.value)
            }}>
             { imagesTitle.map((image, index) => (
               <option  value={index}>{image}</option>
             ))}
             
            </select>)}
      
      {/* Render selected image */}
      <div style={window.innerWidth > 700 ? {width: "70vw"}: {width: "90vw"}}>
        {images[selectedButtonIndex] && <img style={{width: "-webkit-fill-available"}}  src={images[selectedButtonIndex]} alt="Selected" />}
        <br/>
        <br/>
        {technicalInfoUrls[selectedButtonIndex] && <img style={{width: "-webkit-fill-available"}}  src={technicalInfoUrls[selectedButtonIndex]} alt="Selected" />}
        <br/>
      </div>
    </div>
    <CollapsibleSection title="Rules and Regulations">
        <div>
        <p style={{fontSize: "16px", color:"#112348", margin:"16px 0"}}>If you'd like to review our rules and regulations, simply click “Download PDF” below..</p>
        <button className="button" onClick={() => window.open(pdf, "_blank")}>Download PDF</button>
        </div>
      </CollapsibleSection>
    </div> );
}

export default CourseComponent;