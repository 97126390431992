import React from "react";
import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import arrowLeft from "../images/arrowleft.svg";
import arrowRight from "../images/bigArrowRight.svg";
import { getAnalytics, logEvent } from "firebase/analytics";


const handleDragStart = (e) => e.preventDefault();

const Gallery = ({ imageUrls, ids, isActives }) => {

  const analytics = getAnalytics();
  const navigate = useNavigate();
  // Group image URLs into pairs
  const items = [];
  const lastIndex = imageUrls.length - 1;
  for (let i = 0; i < lastIndex; i += 2) {
    items.push(
      <div
        key={i}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "32px",
        }}
      >
        {" "}
        {/* Center images */}
        <img
          src={imageUrls[i]}
          alt={`Image ${i + 1}`}
          onDragStart={handleDragStart}
          role="presentation"
          onClick={() => {
            if(isActives[i])
              navigate(`/event/${ids[i]}`)
              logEvent(analytics, 'Enter_event_Screen');
          }}
          style={{
            width: "621px",
            height: "500px",
            margin: "0 16px",
            cursor: "pointer",
          }} // Fixed width and height for images, adjusted margin
        />
        <img
          src={imageUrls[i + 1]}
          alt={`Image ${i + 2}`}
          onDragStart={handleDragStart}
          onClick={() => {
            if(isActives[i+1])
              navigate(`/event/${ids[i+1]}`)
              logEvent(analytics, 'Enter_event_Screen');
          }}
          role="presentation"
          style={{
            width: "621px",
            height: "500px",
            margin: "0 16px",
            cursor: "pointer",
          }} // Fixed width and height for images, adjusted margin
        />
      </div>
    );
  }

  // Handle the last image if the number of images is odd
  if (imageUrls.length % 2 === 1 && lastIndex == imageUrls.length - 1) {
    items.push(
      <div
        key={lastIndex}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "32px",
        }}
      >
        {" "}
        {/* Center image */}
        <img
          src={imageUrls[lastIndex]}
          alt={`Image ${lastIndex + 1}`}
          onDragStart={handleDragStart}
          onClick={() => {
            if(isActives[lastIndex])
              navigate(`/event/${ids[lastIndex]}`)
          }}
          role="presentation"
          style={{ width: "621px", height: "500px", cursor: "pointer" }} // Fixed width and height for image
        />
      </div>
    );
  }

  const customArrow = (arrow, { isPrev }) => (
    <img
      src={arrow}
      alt={isPrev ? "Previous" : "Next"}
      style={{ zIndex: 2, cursor: "pointer" }}
    />
  );

  return (
    <div
      style={{
        position: "relative",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <AliceCarousel
        mouseTracking
        items={items}
        renderPrevButton={() => (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            {customArrow(arrowLeft, { isPrev: true })}
          </div>
        )}
        renderNextButton={() => (
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            {customArrow(arrowRight, { isPrev: false })}
          </div>
        )}
      />
    </div>
  );
};

export default Gallery;
