import React, { useState } from "react";
import CollapsibleSection from "./collapsibleSection.js";

function AthleteSection({ athleteInfo }) {
  // const [selectedDay, setSelectedDay] = useState("Thursday 24 October");
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  // Function to handle button clicks and change the selected image
  const handleImageSelect = (index) => {
    setSelectedButtonIndex(index);
  };

  const days = athleteInfo.schedule.map(schedule => {
    const date = new Date(schedule.name);
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
  });
  
  const images = athleteInfo.schedule.map(schedule => schedule.scheduleTableUrl);

  return (
    <div style={window.innerWidth > 700 ? {paddingTop: "64px"}: {paddingTop: "16px"}}>
      <div className="registration-firstPart">
        <h3 style={{ textAlign: "start" }}>Event Schedule </h3>
        <br />
        <p style={{ margin: "0", textAlign: "start" }}>
          Please note, this is subject to change and the final version will be
          released in the Athlete Information Guide prior to the event.
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        {window.innerWidth > 700 ? (
          <div className="images-btn-Container">
            {days.map((day, index) => (
              <button
                key={index}
                className={
                  selectedButtonIndex === index ? "image-btn-Clicked" : ""
                }
                onClick={() => handleImageSelect(index)}
              >
                {day}
              </button>
            ))}
          </div>
        ) : (
          <select
            style={{ margin: "20px 0", fontSize: "16px" }}
            value={selectedButtonIndex}
            onChange={(event) => {
              handleImageSelect(event.target.value);
            }}
          >
            {days.map((image, index) => (
              <option value={index}>{image}</option>
            ))}
          </select>
        )}
        <div
          style={
            window.innerWidth > 700 ? { width: "70vw" } : { width: "90vw" }
          }
        >
          {images[selectedButtonIndex] && (
            <img
              style={{ width: "-webkit-fill-available" }}
              src={images[selectedButtonIndex]}
              alt="Selected"
            />
          )}
        </div>
      </div>
      <div className="registration-firstPart" style={{ marginTop: "4em" }}>
        <h3 style={{ textAlign: "start" }}>Athletes Information Guide</h3>
        <br />
        <p style={{ margin: "12px 0", textAlign: "start" }}>
          Please note, this is subject to change and the final version will be
          released in the Athlete Information Guide prior to the event.
        </p>
        <br />
       
      </div>
      <div>
        <CollapsibleSection title="Award Ranking Categories">
          <div>
            <p style={{ fontSize: "16px", color: "#112348", margin: "16px 0" }}>
              Award rankings are given to each male and female of each category
              to the following races;
            </p>
            {athleteInfo.awardRankingCategoriesUrls.map((url, index) => (
        <img key={index} src={url} alt={`Image ${index}`} />
      ))}
          </div>
        </CollapsibleSection>
        <br />
        <CollapsibleSection title="Final World Championship Qualification">
          <div>
            <p style={{ fontSize: "16px", color: "#112348", margin: "16px 0" }}>
              Qulification slot are given to each male and female of each
              category to the following races;
            </p>
            {athleteInfo.finalWorldChampionshipUrls.map((url, index) => (
        <img key={index} src={url} alt={`Image ${index}`} />
      ))}
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
}

export default AthleteSection;
