import React from "react";
import CheckoutContent from "./checkoutContent";
import CheckoutSummary from "./checkoutSummary";

function Checkout(props) {
  return (
    <div>
      <h3
        style={{
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "32px",
          margin: "32px 64px",
        }}
      >
        {" "}
        Order Summary
      </h3>

      <div
        className="checkout-container"
      >
        <CheckoutContent
          data={props.content}
            eventData= {props.eventData}
        />
{console.log(props.eventCartId)}
        <CheckoutSummary price={ props.price } iframeValu={props.iframeValu} iframe={props.iframe} eventCartId={props.eventCartId}/>
      </div>
    </div>
  );
}

export default Checkout;
