import React, { useEffect, useState } from "react";
import "./checkout.scss";
import { Convert } from "easy-currencies";

function CheckoutContent(props) {
  const [convertedData, setConvertedData] = useState([]);
 

  return (
    <>
      <div className="checkout-content-left">
        {props.data &&
          props.data.length > 0 &&
          props.data.map((item, index) => (
            <>
              <div
                className="checkout-title-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ flexShrink: 0 }}>{item.quantity}x</p>{" "}
                {/* Quantity on the left */}
                <span
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginLeft: "10px" }}>{item.name}</p>{" "}
                  {/* Name aligned with details */}
                </span>
                <p>{item.price} USD</p>
              </div>
              <div className="checkout-details-container">
                <p>
                  {item.firstName}, {item.lastName}
                </p>
                <p>{item.gender}</p>
                <p>{item.dateOfBirth.split("T")[0]}</p>
                <p>{item.email}</p>
                <p>{item.phoneNumber}</p>
                <p>{item.nationality}</p>
                <p>{item.country}</p>
                <p>{item.ageCategory}</p>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Safety Buoy</p>
                    <p>Aquaman T-shirt</p>
                    <p>Support T-shirt</p>
                    <p>Aquaman Hoodie</p>
                    <p>Aquaman Cap</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>{item.buoyPrice ? item.buoyPrice : "N/A"} {item.buoyPrice ? "USD" : ""}</p>
                    <p>{item.shirtPrice ? item.shirtPrice : "N/A"} {item.shirtPrice ? "USD" : ""}</p>
                    <p>{item.supportShirtPrice   ? item.supportShirtPrice : "N/A"} {item.supportShirtPrice ? "USD" : ""}</p>
                    <p>{item.hoodiePrice ? item.hoodiePrice : "N/A"} {item.hoodiePrice ? "USD" : ""}</p>
                    <p>{item.capPrice ? item.capPrice : "N/A"} {item.capPrice ? "USD" : ""}</p>
                  </div>
                </span>
              </div>
              {index + 1 !== props.data.length && (
                <div
                  style={{
                    backgroundColor: "#1123484D",
                    height: "1px",
                    width: "97%",
                    margin: "16px auto", // Centering the line
                  }}
                />
              )}
            </>
          ))}
      </div>
    </>
  );
}

export default CheckoutContent;
