import React, {useState} from 'react';
import {countries} from 'countries-list';
import contactUs from '../../api/contactus/contactUs';

function ContactSection() {

    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    info: 'Andorra'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await contactUs(formData).then((_) => {
        alert("Thank you for registering in our newsletter!")
      }); // Call postEventData function with formData
      setFormData({
        name: '',
        email: '',
        info: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again later.');
    }
  };


  const countryNames = Object.values(countries).map(country => country.name);

    return ( <div style={window.innerWidth > 700 ? {paddingTop: "64px"}: {paddingTop: "16px"}}>
        <div className="registration-firstPart">
        <h3>Sign Up For Aquaman Announcements </h3>
        <br/>
        <p>
        Embark on your swimming journey with us, register today, and choose the race that suits you best!
        </p>
        <br/>
      </div>
      <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          id="name"
          name='name'
          placeholder='Your name'
          onChange={handleChange}
          required
        />
      </div>
      <div>
                <input
          type="email"
          id="email"
          name='email'
          placeholder='Email'
          onChange={handleChange}
          required
        />
      </div>
      <div>
      <select
          id="info"
          name='info'
          onChange={handleChange}
          required
        >
          {countryNames.map((countryName, index) => (
            <option key={index} value={countryName}>
              {countryName}
            </option>
          ))}
        </select>
      </div>
      <button style={{marginTop: "3em"}} className="button" type="submit">Sign Up</button>
    </form>
    <p className='contact-bottomTxt'>By entering your information and selecting ‘SIGN UP’ you agree that we may collect and use your personal data provided in this form for the purposes of sending you promotional marketing material.</p>
    </div> );
}

export default ContactSection;