import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAw_DJasZZ_xdW0ktouojy8yrN9Vz1Lx1s",
  authDomain: "aquaman-5b7b3.firebaseapp.com",
  projectId: "aquaman-5b7b3",
  storageBucket: "aquaman-5b7b3.appspot.com",
  messagingSenderId: "691705013772",
  appId: "1:691705013772:web:9360ce6e2150c3f8f97029",
  measurementId: "G-DQYZMQ8Z7H",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage, firebaseConfig };
