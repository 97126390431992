import axios from 'axios';

const applyPromo = (body) => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_BASE_URL + "/promo/apply", body) // Append the id to the URL
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export default applyPromo;