import axios from 'axios';

const checkout = (headers) => {
  return new Promise((resolve, reject) => {
    axios.get(process.env.REACT_APP_BASE_URL + '/payment/checkout', { headers: headers }) // Pass headers
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export default checkout;
