import React from 'react';

const HorizontalLine = ({ color, thickness }) => {
  const style = {
    width: '100%', // Ensures the line fills the width of its container
    height: thickness,
    backgroundColor: color,
    border: 'none',
    margin: '10px 0' // You can adjust the margin as needed
  };

  return <hr style={style} />;
};

export default HorizontalLine;