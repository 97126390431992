import React, { useState } from "react";
import "./MakanakForm.scss";
import fullLogoWhite from "./fullLogoWhite.svg";
import earlyApply from "../api/makanak/earlyApply";
import surveyapi from "../api/makanak/survay";

const MakanakForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [surveySubmit, setSurveySubmit] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  const [survey, setSurvey] = useState({
    question1: {},
    question2: {},
    question3: {},
    question4: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSurveyChange = (e, question) => {
    const { name, value } = e.target;
    
    setSurvey({
      ...survey,
      [name]: {value:value, question: question},
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    earlyApply(formData)
  };

  const handleSubmitSurvey = (e) => {
    e.preventDefault();
    setSurveySubmit(true);
    let surveyCp = {...survey}
    surveyCp.user = formData
    let body = {
        data: surveyCp,
        tag: "Makanak-Pre",
    }
    surveyapi(body)
  }

  return (
    <div className="MainContainer makanak-body">
      <img src={fullLogoWhite} alt="Logo" className="logot" />{" "}
      {/* Logo at the top */}
      <div className="container">
        {!submitted ? (
          <>
            <div className="explanatory-text">
              <div>
                <strong>Tailored Solutions</strong>
                <p>
                  We provide cutting-edge technology solutions tailored for your
                  business.
                </p>
              </div>
              <div>
                <strong>Commitment to Your Goals</strong>
                <p>
                  Our team is committed to helping you achieve your goals with
                  precision.
                </p>
              </div>
              <div>
                <strong>24/7 Support</strong>
                <p>
                  We offer support 24/7 to ensure the best service for our
                  clients.
                </p>
              </div>
              <div>
                <strong>Transform Your Digital Journey</strong>
                <p>
                  Join us to transform your digital journey and unlock new
                  opportunities.
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <button type="submit">Submit</button>
            </form>
          </>
        ) : !surveySubmit?  (
          <div className="survey">
            <h5>Please fill out the survey</h5>
            <form onSubmit={handleSubmitSurvey}>
              {[{question:"How likely are you to use the app to book a private swimming coach for yourself or a family member?",value: "question1"}, {question:"How likely are you use the app to book sessions in a swimming academy for yourself or a family member?",value: "question2"}, {question:"How likely are you to use the app to book a lifeguard privately or for events?",value: "question3"}, {question: "How likely would you use a store for swimming related products",value: "question4"}].map(
                (item, index) => (
                  <div key={index} className="survey-row">
                    <p>{item.question}</p>
                    <div className="survey-options">
                      <label style={{textAlign:"center"}}>
                        <input
                          type="radio"
                          name={item.value}
                          value="1"
                          checked={survey[item.value].value === "1"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                        <strong>1</strong>
                      </label>
                      <label style={{textAlign:"center"}}>
                        <input
                          type="radio"
                          name={item.value}
                          value="2"
                          checked={survey[item.value].value === "2"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>2</strong>
                      </label>
                      <label style={{textAlign:"center"}}>
                        <input
                          type="radio"
                          name={item.value}
                          value="3"
                          checked={survey[item.value].value === "3"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>3</strong>
                      </label>
                      <label style={{textAlign:"center"}}>
                        <input
                          type="radio"
                          name={item.value}
                          value="4"
                          checked={survey[item.value].value === "4"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>4</strong>
                      </label>
                      <label style={{textAlign:"center"}}>
                        <input
                          type="radio"
                          name={item.value}
                          value="5"
                          checked={survey[item.value].value === "5"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>5</strong>
                      </label>
                    </div>
                  </div>
                )
              )}
                <button type="submit">Submit</button>
            </form>
          </div>
        ): (
            <div style={{height: "70vh"}}>
                <h4 style={{color:"#033275"}}>Thank you <strong style={{color:"#3385f9"}}>{formData.name}</strong> for your survey</h4>
            </div>
        )}
      </div>
      <div class="social-footer-mobiles-makanak">
        <ul class="list-social">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80vw",
              margin: "24px auto 8px auto",
            }}
          >
            <div className="insta social-icon" alt="" />
            <div className="facebook social-icon" alt="" />
            <div className="youtube social-icon" alt="" />
            <div className="tiktok social-icon" alt="" />
            <div className="mail social-icon" alt="" />
          </div>
        </ul>
      </div>
      <div class="footer-copy-text-makanak">
        <p>
          Terms and Conditions / Website Terms of Use / Privacy Policy / Cookie
          Preferences © 2024 Makanak
        </p>
      </div>
    </div>
  );
};
export default MakanakForm;
